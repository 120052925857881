
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_93JUPuudVO8CDF1qeOPxvOTbB6jqQmx3_FMlzwpLM4OFcMeta } from "/workspace/pages/blog/[id].vue?macro=true";
import { default as indexCGFa53zpuMKW7nV21qpxhPR6tRGrcsuEZziMFU35BXIMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as blogP3k6OHAymUrsIN4fwvSf4d1puMkncRLgmILbcodQSR0Meta } from "/workspace/pages/blog.vue?macro=true";
import { default as contactFj0aNoRPJSXxj4SXLABzquCnprKOdtFKISUHdP_nOroMeta } from "/workspace/pages/contact.vue?macro=true";
import { default as hallOfFamegIaI7_45yp17fpQIzV6GHB0uyEti3QAZL6Buka__45HYVl4Meta } from "/workspace/pages/hallOfFame.vue?macro=true";
import { default as indexidyDttn2nAtfyKcnzRUSqhwnL7kztssg1Rdvj0_45dfSAMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91id_93E_FFk0GtX8v9MvYYeLEdB_B6Cf1MfIhcFugw21SG0E4Meta } from "/workspace/pages/levels/[id].vue?macro=true";
import { default as _91video_93r31iDyonCWTo_45cu0bADrXGQBc113ZCTBIgf_45KhDgoCQMeta } from "/workspace/pages/levels/[video].vue?macro=true";
import { default as indexo1rGlsq121axNu5DKjSAfAgd5pwLNRkCnU2fwpQsC_45YMeta } from "/workspace/pages/levels/index.vue?macro=true";
import { default as levels3u5liHvYkmp_45XDwHBsMUP8izEiDpIcdXOrex6aYeo9YMeta } from "/workspace/pages/levels.vue?macro=true";
import { default as loginvezcvmMPBN3eB4jBUPlMRRPHMVxJle96Nui3_45afcMrEMeta } from "/workspace/pages/login.vue?macro=true";
import { default as passwordReset8PAX9lRT5KSbIbTKPPzV_gCUHgvhAGFVfR9_Cr_45bCzMMeta } from "/workspace/pages/passwordReset.vue?macro=true";
import { default as registration9yBT8IE4VrUwGc7R5Yym54W7qEFANO8dHWAJ5aABn_45MMeta } from "/workspace/pages/registration.vue?macro=true";
import { default as terms_Ss3567Csoar8BsO2zx9Zd5MNIb1fMYq0B9smBrsfl0Meta } from "/workspace/pages/terms.vue?macro=true";
import { default as user3yCnPZkaFg1HxMj1Jl0ZG1ZsbD2REkmMlET4vqgIty8Meta } from "/workspace/pages/user.vue?macro=true";
export default [
  {
    name: blogP3k6OHAymUrsIN4fwvSf4d1puMkncRLgmILbcodQSR0Meta?.name,
    path: "/blog",
    component: () => import("/workspace/pages/blog.vue"),
    children: [
  {
    name: "blog-id___cs",
    path: ":id()",
    component: () => import("/workspace/pages/blog/[id].vue")
  },
  {
    name: "blog___cs",
    path: "",
    component: () => import("/workspace/pages/blog/index.vue")
  }
]
  },
  {
    name: "contact___cs",
    path: "/napiste-andelovi",
    component: () => import("/workspace/pages/contact.vue")
  },
  {
    name: "hallOfFame___cs",
    path: "/sin-slavy",
    component: () => import("/workspace/pages/hallOfFame.vue")
  },
  {
    name: "index___cs",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: levels3u5liHvYkmp_45XDwHBsMUP8izEiDpIcdXOrex6aYeo9YMeta?.name,
    path: "/levels",
    component: () => import("/workspace/pages/levels.vue"),
    children: [
  {
    name: "levels-id___cs",
    path: "/urovne/:id()",
    component: () => import("/workspace/pages/levels/[id].vue")
  },
  {
    name: "levels-video___cs",
    path: "/urovne/:id()/:video()",
    component: () => import("/workspace/pages/levels/[video].vue")
  },
  {
    name: "levels___cs",
    path: "/urovne",
    component: () => import("/workspace/pages/levels/index.vue")
  }
]
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginvezcvmMPBN3eB4jBUPlMRRPHMVxJle96Nui3_45afcMrEMeta || {},
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "passwordReset___cs",
    path: "/zapomenute-heslo",
    meta: passwordReset8PAX9lRT5KSbIbTKPPzV_gCUHgvhAGFVfR9_Cr_45bCzMMeta || {},
    component: () => import("/workspace/pages/passwordReset.vue")
  },
  {
    name: "registration___cs",
    path: "/registrace",
    meta: registration9yBT8IE4VrUwGc7R5Yym54W7qEFANO8dHWAJ5aABn_45MMeta || {},
    component: () => import("/workspace/pages/registration.vue")
  },
  {
    name: "terms___cs",
    path: "/obchodni-podminky",
    meta: terms_Ss3567Csoar8BsO2zx9Zd5MNIb1fMYq0B9smBrsfl0Meta || {},
    component: () => import("/workspace/pages/terms.vue")
  },
  {
    name: "user___cs",
    path: "/hracsky-profil",
    component: () => import("/workspace/pages/user.vue")
  }
]